@import "../../../../../theme/variables.scss";
@import "../../../../../theme/animations.scss";

.picking-cartao-main-container {
  height: 100%;
  display: flex;
  padding: 30px;

  .backdrop {
    background: $systemForeground;
    width: 100%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    box-shadow:
      0px 2px 4px -2px rgba(16, 24, 40, 0.06),
      0px 4px 8px -2px rgba(16, 24, 40, 0.1);
  }

  &.fullscreen {
    border-radius: 0;
    min-height: 100%;
    height: fit-content;
    width: 100%;
    position: absolute;
    padding: 18px;
    background: rgba(16, 24, 40, 0.5);
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;

    .backdrop {
      min-height: calc(100vh - 36px);
      height: 100%;
      width: 100%;
    }
  }

  .icon {
    height: 34px;
    width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 4px;

    &:hover {
      background: $systemBackground;
      cursor: pointer;
    }
  }

  .content-container {
    height: 100%;
    overflow: auto;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    border-top: $defaultBorder;

    .fields-container {
      flex-basis: 400px;
      display: flex;
      align-items: center;
    }

    .input-section {
      flex-basis: 40%;
      background-color: var(--systemForeground);
      border-right: $defaultBorder;
      padding: 16px 24px;
      overflow: auto;
      gap: 0px;

      .form-picking-cartao {
        gap: 0px !important;
        padding-bottom: 0px !important;
        height: auto;

        .form-intern-wrapper {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
      }

      p {
        color: var(--textSecondary);
      }
    }

    .view-section {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      padding: 16px 24px;
      border-left: 1px solid var(--systemBackground);
      background-color: var(--itemListBackground);
      overflow: auto;

      .bigger-input {
        flex-basis: 70%;
      }

      .list-label {
        margin: 0;
        color: var(--textSecondary);

        &:first-child {
          margin-right: 20px;
        }
      }

      .tags {
        margin-left: auto;

        .p-tag {
          margin-left: 5px;
        }
      }

      .item-list {
        position: relative;
        height: 100%;
        overflow: auto;
        display: flex;

        .p-accordion-header-link {
          background: $systemForeground;
        }

        .p-accordion-header-link,
        .p-toggleable-content,
        .p-accordion-tab,
        .p-accordion-content {
          border-color: var(--accordeonBorderColor);
        }

        .p-accordion-content {
          overflow: auto;
        }

        .items {
          position: absolute;
          width: 100%;

          .accordionHeaderTemplate {
            display: grid;
            grid-template-columns: 6fr 6fr 2fr 1fr;
            gap: 30px;
            width: 100%;

            span {
              color: $textSecondary;
            }

            .status {
              height: 20px;
              width: 20px;
              background: red;
              border-radius: 50%;

              &:last-child {
                margin-left: auto;
              }
            }
          }
        }
      }
    }
  }

  .footer-container {
    display: flex;
    border-top: var(--defaultBorder);
    padding: 16px 24px;
    gap: 10px;
    justify-content: flex-end;

    .dcp-button:nth-child(1) {
      margin-right: auto;
    }

    .dcp-button:nth-child(2) {
      color: red;
    }
  }
}

.wms-login-dialog {
  width: 400px;

  .p-dialog-content {
    padding-bottom: 0px;
  }

  .buttom-container {
    justify-content: flex-end;
  }
}

.wms-password {
  .p-inputtext,
  .p-password-input {
    width: 100%;
  }
  .p-icon {
    height: 15px;
    width: 15px;
    margin-left: 93%;
  }
}

.hide-confirm-dialog {
  .buttons-row {
    justify-content: flex-end;
  }
  .dcp-form {
    padding-bottom: 0;
  }
  p {
    font-weight: 500;
    font-size: 14px;
    color: var(--textSecondary);
  }
}
