@import "../../../../theme/variables.scss";

.login {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--systemForeground);

  .login-form {
    display: flex;
    flex-direction: row;
    border-radius: 20px;
    box-shadow: 0px 32px 64px -12px rgba(16, 24, 40, 0.14);
    background-color: $systemForeground;
    max-width: 770px;
    width: 90%;
    height: 748px;
    overflow: hidden;

    .side-banner-wrapper {
      flex-grow: 1;
      max-width: 300px;
      background-color: var(--systemForeground);
      overflow: hidden;

      .side-banner {
        width: 100%;
        height: 100%;
        background-image: url("../../../../theme/media/backgrounds/login-banner.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-color: var(--sideFormBackgroundColor);
      }

      .duett-banner {
        width: 100%;
        height: 100%;
        background-image: url("../../../../theme/media/backgrounds/login-banner.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 55%;
        background-position-y: -30px;
        scale: 1.5;
        background-color: var(--sideFormBackgroundColor);
      }
    }

    .form {
      flex-grow: 1;
      max-width: 470px;
      padding: 133px 50px;
      background-color: var(--formBackgroundColor);

      .form-header {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        gap: 20px;

        .duett-logo {
          height: 60px;
        }

        .header-message {
          font-size: 16px;
          font-weight: 400;
          color: $textSecondary;
          text-align: center;

          .new-password-message {
            padding-top: 20px;
          }
        }

        .header-message-access-denied {
          font-size: 24px;
          font-weight: 600;
          color: $textSecondary;
          text-align: center;
        }
      }

      .domain-back-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 50px 50px;
        height: 32px;
        max-width: 250px;
        width: 100%;
        border-radius: 20px;
        background: var(--inputBackgroundColor);

        .domain-wrapper {
          display: flex;
          padding: 10px 0px 10px 14px;
          align-items: center;
          flex: 1 0 0;
          display: flex;

          .domain-text {
            width: 100%;
            max-width: 190px;
            color: var(--textPrimary);
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            align-self: center;
          }
        }

        .back-icon {
          display: flex;
          padding: 10px 12px 10px 14px;
          transition: 0.2s;
          cursor: pointer;

          &:hover {
            filter: brightness(0.5);
          }
        }
      }

      .form-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;

        .domain-wrapper {
          display: flex;
          padding: 50px 0px 45px 0px;

          .domain-input-container {
            .domain-input {
              background-color: var(--inputBackgroundColor) !important;
            }
          }
        }

        .pw-field {
          .p-icon-field {
            width: 100%;
          }

          .p-inputtext {
            width: 100%;
            background-color: var(--inputBackgroundColor);
          }

          .p-input-icon {
            height: 15px;
            width: 15px;

            .p-password-show-icon,
            .p-password-hide-icon {
              margin-left: 0%;
            }
          }
        }

        .p-password .p-password-show-icon,
        .p-password .p-password-hide-icon {
          margin-left: 94%;
          color: var(--textPrimary);
        }

        .button-message {
          font-size: 16px;
          font-weight: 400;
          color: $textSecondary;
          text-align: center;
          padding-bottom: 10px;
        }

        .new-pass-row-wrapper {
          display: flex;
          padding-top: 30px;
        }

        .new-password-image {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 45px 0px 36px 0px;
        }

        .body-actions {
          display: flex;
          flex-direction: column;
          gap: 20px;
          margin-bottom: 12px;

          .next-button-wrapper {
            display: flex;

            .button-login-next {
              display: flex;
              flex-grow: 1;
            }
          }

          .social-login-wrapper {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .p-button-login-sso {
              display: flex;
              flex-wrap: wrap;
              flex-direction: column;
              align-content: center;
              padding: 10px 16px;
              justify-content: center;
              align-items: center;
              gap: 12px;
              align-self: stretch;

              .sso-button-label {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                color: var(--textPrimary);
              }
            }
          }

          .password-reset-button {
            text-align: flex-end;
            font-weight: 500;
            font-size: 14px;
            color: $primary;

            &:hover {
              color: $primaryHover;
            }
          }

          .dcp-login-custom-button {
            display: flex;
            background-color: $systemForeground;
            border: solid 1px #d0d5dd;

            .dcp-login-custom-button-content {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 12px;
            }
          }
        }

        .password-complexity-check {
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          row-gap: 15px;
          column-gap: 20px;
          padding: 20px 0;

          .check {
            display: flex;
            gap: 10px;

            .icon-container {
              width: 20px;
              height: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #f2f4f7;
              border-radius: $buttonBorderRadius;
            }

            .label {
              color: var(--gray-500, #667085);
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
            }

            &.checked {
              .label {
                color: var(--green-500);
              }

              .icon-container {
                background: var(--green-100);
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $mobileBreakPoint) {
  .login {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--systemBackground);

    .login-form {
      display: flex;
      border-radius: 0px;
      justify-content: center;
      box-shadow: none;
      height: 100% !important;
      width: 100% !important;
      max-width: 100% !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
      background-color: $loginBackground !important;

      .side-banner-wrapper {
        display: none;
      }

      .form {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        padding-top: 20px !important;
        min-height: 800px;
      }

      .form-body {
        justify-content: flex-start !important;
        width: 100%;
        padding: 0px 30px;
      }
    }
  }
}
