@import "../../../../theme/variables.scss";

.timeline {
  list-style: none;
  padding: 0;
  margin: 0;
}
.timeline-item {
  display: flex;
  margin-bottom: 5px;
}

.timeline-icon {
  display: flex;
  align-items: center;
  border-radius: 50%;
  margin-left: 18px;

  .timeline-step-done {
    display: flex;
    align-items: center;
    height: 40px;
    width: 40px;
    background-color: var(--systemBackground);
    color: var(--green-100);
    padding: 8px;
    border-radius: 50%;
  }

  .timeline-blank-step {
    display: flex;
    align-items: center;
    height: 40px;
    width: 40px;
    background-color: var(--systemBackground);
    color: var(--textPrimary);
    padding: 8px;
    border-radius: 50%;

    .timeline-step-number {
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
  }

  .timeline-error-step {
    display: flex;
    align-items: center;
    height: 40px;
    width: 40px;
    background-color: #f63d68;
    padding: 10px;
    border-radius: 50%;
  }
}

.timeline-separator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 37px;
  width: 2px;
  background-color: var(--systemBackground);
  height: 50px;
}

.timeline-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 18px;
  padding: 8px 8px;
  border: 0px solid #ccc;
  border-radius: 4px;
  color: var(--textPrimary);
  &.strong {
    font-weight: bold;
    font-size: 14px;
  }

  &.timeline-content p {
    padding: 0px;
    margin: 0px;
    font-size: 12px;
  }

  .description {
    margin-top: 5px;
    font-size: 12px;
  }
}
