.modal-update-order-status {
  width: 30vw;

  .update-status-dialog-container {
    margin-bottom: 1rem;
    margin-top: 1rem;

    .status-dropdown {
      width: 100%;
    }
  }
}
