.inventory-details-dialog {
  width: 70vw;
  background-color: var(--systemForeground);
  .p-dialog-header {
    padding: 30px 25px 20px 30px !important;
  }
  .inventory-type-wrapper {
    display: flex !important;
    justify-content: space-between !important;
  }

  .p-dialog-footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    border-top: 1px solid var(--systemBackground);
    align-items: center;
  }

  .form-inventory-details-container {
    padding: 10px;
  }
}
