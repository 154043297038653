@import "../../../../theme/variables.scss";

.order-monitor-details {
  width: 100% !important;
  max-width: 1173px;
  max-height: 710px;
  height: 100%;

  .p-dialog-content {
    height: 100%;
    overflow: hidden;
    padding: 0px !important;
  }

  .timeline-title {
    font-weight: 500;
    color: var(--textPrimary);
    margin-bottom: 16px;
  }

  .order-monitor-details-header {
    padding: 20px;
    border-bottom: 1px solid var(--systemBackground);
  }

  .p-tabview-nav-container {
    display: flex;
    justify-content: space-between;
    padding-left: 30px;
    border-bottom: 1px solid var(--systemBackground);

    .report-track-button-wrapper {
      display: flex;
    }

    .p-tabview-nav-content {
      width: 100%;
    }
  }

  .p-tabview .p-tabview-nav {
    background-color: var(--systemForeground);
    border: none !important;
  }

  .tab-view-container {
    background-color: var(--systemForeground);
    width: 100%;
    height: 100%;

    .p-tabview-panels {
      height: 100%;
      padding: 0px !important;
      background-color: var(--systemForeground);

      .p-tabview-panel {
        height: 100%;
      }
    }

    .tab-header {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;

      .tab-panel-title {
        font-size: 14px !important;
        font-weight: 500 !important;
        color: var(--textPrimary) !important;
      }
    }

    .tab-report-track-number-header {
      display: flex;
      width: 100%;
      flex-direction: row-reverse;
      gap: 8px;
      align-items: center;
      padding: 0px 20px;
    }

    .order-tab-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      height: 100%;

      .order-left-side-wrapper {
        display: flex;
        padding: 30px;
        flex-direction: column;
        gap: 24px;
        width: 900px;
        overflow: auto;
        height: 100%;
        max-height: 513px;

        .info-grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 16px;
          align-items: center;
          .info-grid-item {
            display: flex;
            flex-direction: column;
            height: 67px;

            .city-field-wrapper {
              height: 46px;
            }

            .track-number-field-wrapper {
              height: 46px;
              .track-number-link {
                color: var(--primary);
                padding: 8px 0px;
                cursor: pointer;
              }
            }
            .status-wrapper {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 8px;

              .edit-status-button {
                border: none;
                display: flex;
                justify-content: right;
                align-items: center;

                .icon-status-button {
                  display: flex;
                }
              }
            }
          }

          .info-grid-item label {
            color: var(--textPrimary);
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 4px;
          }

          .info-grid-item span {
            font-size: 15px;
            color: var(--textPrimary);
            font-weight: 600;
            padding: 8px 0px;
            border-radius: 4px;
          }

          .info-grid-item .p-inputtext {
            width: 100%;
          }

          .info-grid-item .carrier-dropdown {
            padding: 5px 10px;
            height: 40px;
            align-items: center;
          }
        }

        .skeleton.row {
          display: flex;
          gap: 20px;

          .skeleton-info-grid-item {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 16px;
            align-items: center;

            .skeleton-item-wrapper {
              display: flex;
              flex-direction: column;
              gap: 8px;
              width: 100%;
            }
          }

          .skeleton-log-process-wrapper {
            display: flex;
            flex-direction: column;
            gap: 16px;
            height: 100%;
            width: 100%;
            border-radius: 8px;
            overflow: auto;
            max-height: 280px;
            min-height: 240px;
          }
        }

        .log-process-wrapper {
          label {
            color: var(--textPrimary);
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 4px;
          }
          .span-logs {
            color: var(--textPrimary);
            display: flex;
            width: 100%;
            flex-direction: column;
            gap: 16px;
            margin-top: 16px;
            background-color: var(--inputBackgroundColor);
            padding: 16px;
            border-radius: 8px;
            overflow: auto;
            max-height: 280px;
            min-height: 240px;
            white-space: pre-line;
          }
        }
      }

      .timeline-container {
        width: 400px;
        height: 100%;
        max-height: 510px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        padding: 16px;
        border-left: 2px solid var(--systemBackground);
      }
    }

    .monitor-pictures-view {
      display: flex;
      flex-direction: row;
      gap: 20px;
      padding: 20px 30px;

      .row {
        display: flex;
        gap: 20px;
      }
      .loading {
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: 100%;
      }

      .images-container {
        height: 100%;
        display: flex;
        gap: 10px;
        .order-wrapper {
          display: flex;
          flex-direction: column;
          gap: 20px;

          .image-wrapper {
            padding: 10px 6px 6px 6px;
            background: var(--systemBackground);
            border-radius: 6px;
            width: 162px;
            height: 210px;

            .images-preview-thumbnail {
              height: 150px !important;
              width: 150px !important;
            }

            .icon {
              display: none;
            }

            &:hover {
              .icon {
                display: flex;
                align-items: center;
              }
            }

            .image-header {
              display: flex;
              gap: 10px;
              margin-bottom: 10px;
              min-height: 32px;

              .filename-wrapper {
                display: flex;
                align-items: center;
                gap: 10px;
                flex-grow: 1;

                .img-icon {
                  flex-basis: 20px;
                  width: 20px;
                }

                .filename {
                  flex-grow: 1;
                  margin: 0;
                  color: var(--textPrimary);
                  font-size: 12px;
                  font-weight: 500;
                  text-overflow: ellipsis;
                  flex-basis: 0;
                }
              }
            }

            .p-image-preview-container {
              border-radius: 6px;
              background-color: #d1d1d1;
              display: flex;
              justify-content: center;
            }

            img {
              height: 150px;
              border-radius: 6px;
              background-position: center center;
              background-repeat: no-repeat;
            }
          }
        }
      }
      .no-images {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 100%;
        font-size: 18px;
        font-weight: 600;
        color: var(--textPrimary);
      }
    }
  }
  .footer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    border-top: 1px solid var(--systemBackground);
    gap: 16px;
    padding: 18px 18px 18px 0px !important;
  }
  .p-dialog-footer {
    flex-grow: 1;
    display: flex;
    gap: 16px;
    padding: 0px !important;
  }
}

.download-list-columns-database-view {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--systemForeground);

  .p-overlaypanel-content {
    padding: 0px;
    background-color: var(--tableBodyBackground);
    color: var(--textPrimary);
    .popup-wrapper {
      .popup-header {
        display: flex;
        height: 44px;
        width: 240px;
        border-bottom: 1px solid var(--systemBackground);
        margin-bottom: 10px;

        .popup-title {
          height: 20px;
          padding: 12px 17px;
          display: flex;
          align-items: flex-start;
          color: var(--textPrimary);
          font-size: 16px;
          font-weight: 600;
        }
      }

      .popup-column-download-wrapper {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 12px;

        .popup-excel-download-option,
        .popup-pdf-download-option,
        .popup-csv-download-option {
          cursor: pointer;
          display: flex;
          width: 100%;
          height: 34px;
          padding: 7px 16px;
          align-items: center;
          gap: 12px;
          transition: 0.2s;

          .popup-download-label {
            cursor: pointer;
            color: var(--textPrimary);
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
          }
          &:hover {
            background-color: var(--systemBackground);
          }
        }
      }
    }
  }
}
