@import "../../theme/variables.scss";

.login-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $loginBackground;

  .content {
    height: 100%;
    width: 100%;

    .body {
      height: 100%;
      width: 100%;
    }
  }

  @media (max-width: $mobileBreakPoint) {
    .content {
      height: auto !important;
    }
  }
}
