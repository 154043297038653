@import "../../../../theme/variables.scss";
@import "../../../../theme/animations.scss";

.orders-monitor-dashboard {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: $systemBackground;

  .field-dropdown-input {
    max-width: 650px;

    .p-multiselect-label-container {
      display: flex;
      align-items: center;
      gap: 5px;
      height: 40px;
      width: 100%;

      .item-wrapper {
        padding: 0.375rem 0.75rem;
        margin-right: 0.5rem;
        background: var(--reportAccessTagBackgroundColor);
        color: var(--textPrimary);
        border-radius: 16px;
      }
    }
  }

  // override prime loading screen
  .p-datatable-loading-overlay {
    background: #eaecf5;
    opacity: 0.3;
  }

  .search-input-wrapper {
    display: flex;
    width: 250px;
    height: 32px;
    padding: 10px 14px;
    align-items: center;
    gap: 8px;

    .dcp-input {
      display: flex;
      align-items: center;
      gap: 8px;
      flex: 1 0 0;
      background: var(--inputBackgroundColor, #fff);
    }

    .search-field-icon {
      padding: 5px 5px 1px;
    }
  }

  .filters-wrapper {
    display: flex;
    gap: 5px;

    .filter-button {
      display: flex;
      align-items: center;
      padding: 10px;
      gap: 8px;

      .filter-icon {
        color: $textSecondary;
      }

      .filter-text {
        color: $textSecondary;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }

  .orders-monitor-container {
    background-color: $systemBackground;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 34px;
    gap: 10px;

    .orders-monitor-content {
      flex: 1;
      border-radius: $panelBorderRadius;
      border: solid 1px var(--painelBorderColor);
      background-color: $systemForeground;
      display: flex;
      flex-direction: column;
      border-radius: $panelBorderRadius;
      border: solid 1px var(--painelBorderColor);

      .content-header {
        height: 85px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 20px;
        overflow-y: auto;

        .total-items-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;

          .header-message {
            width: 100px;
            display: flex;
            flex-direction: column;
            gap: 5px;

            .total-register-message {
              padding: 2px 0px;
              text-align: center;
              font-size: 13px;
              font-weight: 500;
              line-height: 18px;
              color: $tableHeaderTitle;
            }

            .page-identifier-wrapper {
              display: flex;
              align-items: center;
              gap: 10px;
              flex-grow: 1;

              .page-icon {
                color: $indigo;
              }

              .page-name {
                display: flex;
                align-items: center;
                color: $textPrimary;
                font-weight: 700;
                font-size: 16px;

                .users-icon {
                  margin-right: 10px;
                }
              }
            }
          }
        }

        .options-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 20px;

          .filter-buttons-wrapper {
            display: flex;
            gap: 20px;
            width: 100%;
            border-right: 1px solid var(--systemBackground);
            padding-right: 20px;

            .search-input {
              display: flex;
              align-items: center;
              width: 220px;
              height: 40px;
              background: var(--inputBackgroundColor);
              border-radius: 8px;
              padding: 10px 14px;
            }

            .field-search,
            .field-dropdown {
              width: fit-content;
            }

            .field-calendar {
              display: flex;
              align-items: center;
              width: 240px;
              height: 40px;
              background: var(--inputBackgroundColor);
              border-radius: 8px;
              padding: 10px 14px;

              .field-calendar-input {
                width: 220px;
              }
            }
          }

          .btn-download-database {
            gap: 0px;
          }
        }
      }

      .content-body {
        .user-cell {
          display: flex;
          align-items: center;

          .user-identification {
            font-size: 16px;
            display: inline-flex;
            font-weight: 500;
            width: 32px;
            height: 32px;
            padding: 4px 0px;
            justify-content: center;
            align-items: center;
            margin-right: 12px;
            border-radius: 200px;

            &.active {
              color: var(--tableAvatarLabelColor);
              background: var(--tableAvatarLabelBackground);
            }
          }

          .user-name {
            flex: 1 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: wrap;
          }
        }

        .order-status {
          display: flex;
          flex-direction: row;
          gap: 8px;
          align-items: center;
          font-weight: 600;
        }

        .action-icon {
          cursor: pointer;
        }

        .status-cell {
          display: flex;
          align-items: center;

          .status-identification {
            font-size: 14px;
            display: inline-flex;
            font-weight: 500;
            height: 22px;
            padding: 4px 0px;
            justify-content: center;
            align-items: center;
            margin-right: 12px;
            border-radius: 16px;
            gap: 3px;

            &.active {
              width: 56px;
              color: $tableStatusLabelActive;
              background: $tableStatusLabelBackgroundActive;
            }

            &.inactive {
              width: 68px;
              color: $tableStatusLabelInactive;
              background-color: $tableStatusLabelBackgroundInactive;
            }
          }

          .user-name {
            flex: 1 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: wrap;
          }
        }

        .active-wrapper-badge {
          display: flex;
          padding: 2px 8px 2px 6px;
          justify-content: center;
          align-items: center;
          gap: 6px;
          border-radius: 16px;
          background: var(--green-50, #e3f8f1);

          .status {
            color: var(--green-700, #017954);
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
          }
        }

        .inactive-wrapper-badge {
          display: flex;
          padding: 2px 8px 2px 6px;
          justify-content: center;
          align-items: center;
          gap: 6px;
          border-radius: 16px;
          background: var(--blue-gray-50, #f8f9fc);
          color: var(--blue-gray-500, #4e5ba6);
          text-align: center;
          font-size: 14px;
          font-weight: 500;
        }

        .column-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          gap: 4px;

          .column-actions {
            display: flex;
            height: 30px;
            align-items: center;

            .icon-wrapper {
              display: flex;
              justify-content: center;
              padding: 10px;
              transition: 0.2s;
              border-radius: 8px;
              cursor: pointer;

              .icon-edit {
                color: var(--tableIconColor) !important;
              }

              &:hover {
                padding: 10px;
                border-radius: 8px;
                background: var(--systemBackground);
              }
            }
          }
        }

        .products-wrapper {
          display: flex;
          gap: 4px;

          .project {
            height: fit-content;
            width: max-content;
            border-radius: 16px;
            padding: 2px 8px 2px 8px;
            font-weight: 400;
            line-height: 18px;

            &.product-wms {
              background: $tableProductWmsBgColor;
              color: $tableProductWmsLabelColor;
            }

            &.product-platform {
              background: $tableProductPlatformBgColor;
              color: $tableProductPlatformLabelColor;
            }

            &.product-sequence {
              background: $tableProductSequenceBgColor;
              color: $tableProductSequenceLabelColor;
            }

            &.product-inventory {
              background: $tableProductInventoryBgColor;
              color: $tableProductInventoryLabelColor;
            }

            &.product-mes {
              background: $tableProductMesBgColor;
              color: $tableProductMesLabelColor;
            }

            &.product-warehouse {
              background: $tableProductWarehouseBgColor;
              color: $tableProductWarehouseLabelColor;
            }

            &.product-area-movement-control {
              background: $tableProductAreaMovementControlBgColor;
              color: $tableProductAreaMovementControlLabelColor;
            }
          }

          .remaining-projects {
            display: flex;
            padding: 2px 8px;
            justify-content: center;
            align-items: center;
            border-radius: 16px;
            background: $tableProductCountBg;
            color: $tableProductCountLabel;
          }
        }
      }
    }
  }

  .p-datatable .p-paginator-bottom {
    border-radius: 10px !important;
  }
}
