@import "../../../../../../../../theme/variables.scss";

.card-orders-chart-container {
  height: 100%;
  width: 100%;

  &.small {
    .allocated-orders {
      height: 100%;
      margin: 0 auto;
      padding: 16px;
      border-radius: 8px;
      .card-order-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        height: 100%;

        .card-order {
          flex: 1 1 100%;
          border: 2px solid transparent;
          border-radius: 8px;
          padding: 16px;
          display: flex;
          align-items: center;
          gap: 16px;
          background-color: var(--systemForeground);
          transition: transform 0.3s;

          .card-order-icon {
            width: 55px;
            height: 55px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            .card-order-icon-image {
              width: 45px;
              height: 45px;
            }
          }

          .card-order-content {
            display: flex;
            flex-direction: column;
            .card-order-value {
              font-size: 20px;
              font-weight: 600;
              color: var(--textPrimary);
            }

            .card-order-description {
              font-size: 12px;
              font-weight: 500;
              color: var(--textTertiary);
            }
          }
        }
        .card-order:hover {
          transform: scale(1.05);
        }
      }
    }
  }

  &.large {
    .allocated-orders {
      height: 100%;
      margin: 0 auto;
      padding: 16px;
      border-radius: 8px;

      .card-order-container {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
        justify-content: space-between;
        height: 100%;

        .card-order {
          flex: 1 1 30%;
          border: 2px solid transparent;
          border-radius: 8px;
          padding: 16px;
          display: flex;
          align-items: center;
          gap: 16px;
          background-color: var(--systemForeground);
          transition: transform 0.3s;

          .card-order-icon {
            width: 55px;
            height: 55px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            .card-order-icon-image {
              width: 60px;
              height: 60px;
            }
          }

          .card-order-content {
            display: flex;
            flex-direction: column;
            .card-order-value {
              font-size: 20px;
              font-weight: 600;
              color: var(--textPrimary);
            }

            .card-order-description {
              font-size: 15px;
              font-weight: 500;
              color: var(--textTertiary);
            }
          }
        }

        .card-order:hover {
          transform: scale(1.05);
        }
      }
    }
  }
}
