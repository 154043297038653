@import "../../../../../../../../theme/variables.scss";

.b2b-orders-with-error-status {
  .b2b-orders-with-error-status-body-header {
    margin-left: 10px;
    margin-bottom: 10px;

    .total-items-count {
      color: rgba(90, 94, 236, 255);
    }
  }

  .b2b-orders-with-error-status-body {
    max-height: 400px;
    display: flex;
    flex-direction: column;
    align-content: center;
    gap: 20px;

    .btn-area-contains {
      display: flex;
      justify-content: center;

      .btn-show-more {
        max-width: 150px;
        color: $textTertiary;
        height: 40px;
      }
    }

    .order-status {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
      font-weight: 600;
    }
  }
}
