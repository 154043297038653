@import "../../../../../../../../theme//variables.scss";

.serial-number-dashboard {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .collaborator-calendar-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 6px;
    font-size: 13px;
    font-weight: 600;

    .p-button-plain {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      border: none;
      border-radius: 50%;
      cursor: pointer;
    }
  }

  .content-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    margin-top: 10px;
    overflow-y: auto;

    .collaborator-wrapper {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      min-width: 200px;
      height: 100%;

      .collaborator-header {
        display: flex;
        justify-content: space-between;
        height: 34px;
        align-items: center;
        gap: 6px;

        .column-collaborator {
          display: flex;
          font-size: 15px;
          font-weight: 600;
          min-height: 30px;
          min-width: 30px;
          width: 100%;
          align-items: center;
          gap: 13px;
          padding: 10px;
        }
      }

      .collaborator-content {
        display: flex;
        height: 100%;
        flex-direction: column;
        flex-wrap: nowrap;
      }

      .collaborator-row {
        display: flex;
        justify-content: flex-start;
        padding: 0px 10px 0px 10px;
        align-items: center;
        text-align: center;
        width: 100%;
        min-height: 50px !important;
        max-height: 50px !important;
        font-size: 14px;
        font-weight: 500;
      }

      .dash-content-row-selected {
        border-bottom-left-radius: 6px;
        border-top-left-radius: 6px;
        background-color: var(--systemBackground);
      }
    }

    .hours-wrapper {
      display: flex;
      overflow-x: auto;
      flex-direction: column;
      flex-wrap: nowrap;
      max-width: 100%;
      white-space: nowrap;

      .hours-header {
        display: flex;
        font-size: 15px;
        font-weight: 600;
        min-height: 30px;
        min-width: 30px;
        width: 1200px;
        align-items: center;
        justify-content: space-evenly;
        padding: 0px 6px 0px 0px;

        .column-time {
          height: 37px !important;
          width: 27px !important;
          padding: 10px;
        }
      }

      .hours-content {
        display: flex;
        flex-direction: column;
        width: 1200px;
        height: 100%;

        .hour-row {
          display: flex;
          align-content: center;
          flex-wrap: wrap;
          justify-content: space-evenly;
          padding: 0px 5px 0px 8px;
          min-height: 50px !important;
        }

        .dash-content-row-selected {
          display: flex;
          width: 100% !important;
          background-color: var(--systemBackground);
        }

        .dash-content-cel {
          height: 30px !important;
          width: 30px !important;
          padding: 7px;
          border: 1px solid none;

          .dot {
            margin: 0px;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .dot-green {
          height: 30px !important;
          width: 30px !important;
          background-color: var(--green-500);
          border-radius: 100%;
        }

        .dot-yellow {
          height: 30px !important;
          width: 30px !important;
          background-color: var(--yellow-500);
          border-radius: 100%;
        }

        .dot-red {
          height: 30px !important;
          width: 30px !important;
          background-color: var(--red-500);
          border-radius: 100%;
        }
      }
    }

    .table-right-data-content {
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.1);
      border-radius: 6px;

      .average-production-wrapper {

        .column-average-prod {
          padding: 7px 6px 10px 15px;
          width: 135px;
          font-size: 14px;
          font-weight: 600;
          text-align: center;
        }

        .collaborator-average-content {
          display: flex;
          height: 100%;
          flex-direction: column;
          flex-wrap: nowrap;
        }

        .collaborator-average-footer {
          .collaborator-average-total {
            font-weight: 600;
            display: flex;
            justify-content: center;
            padding: 5px 5px 5px 5px;
            align-items: center;
            text-align: center;
            width: 100%;
            min-height: 50px !important;
            max-height: 50px;
          }

          .collaborator-average-footer-divider {
            width: 100%;
            height: 1px;
            background-color: var(--painelBorderColor);
          }
        }

        .collaborator-average-row {
          display: flex;
          font-weight: 600;
          justify-content: center;
          padding: 5px 5px 5px 5px;
          align-items: center;
          text-align: center;
          width: 100%;
          min-height: 50px !important;
          max-height: 50px;
        }

        .dash-content-row-selected {
          background-color: var(--systemBackground);
        }

        .dash-content-cel {
          height: 30px !important;
          width: 30px !important;
          padding: 5px;

          .dot {
            height: 20px !important;
            width: 20px !important;
            margin: 0px;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .dot-green {
          background-color: var(--green-500);
          border-radius: 100%;
        }

        .dot-yellow {
          background-color: var(--yellow-500);
          border-radius: 100%;
        }

        .dot-red {
          background-color: var(--red-500);
          border-radius: 100%;
        }
      }

      .total-production-wrapper {
        .column-total-prod {
          padding: 7px 6px 10px 0px;
          width: 55px;
          font-size: 14px;
          font-weight: 600;
        }

        .collaborator-total-content {
          display: flex;
          height: 100%;
          flex-direction: column;
          flex-wrap: nowrap;
        }

        .collaborator-total-footer {

          .collaborator-total-total {
            font-weight: 600;
            display: flex;
            justify-content: flex-start;
            padding: 5px 5px 5px 5px;
            align-items: center;
            text-align: center;
            width: 100%;
            min-height: 50px !important;
            max-height: 50px;
          }

          .collaborator-total-footer-divider {
            width: 100%;
            height: 1px;
            background-color: var(--painelBorderColor);
          }
        }

        .collaborator-row {
          font-weight: 600;
          display: flex;
          justify-content: flex-start;
          padding: 5px 5px 5px 5px;
          align-items: center;
          text-align: center;
          width: 100%;
          min-height: 50px !important;
          max-height: 50px;
        }

        .dash-content-row-selected {
          background-color: var(--systemBackground);
        }
      }

      .production-wrapper {
        .column-prod {
          padding: 7px 5px 10px 5px;
          font-size: 14px;
          font-weight: 600;
        }

        .collaborator-content {
          display: flex;
          height: 100%;
          flex-direction: column;
          flex-wrap: nowrap;
        }

        .colaborator-production-divider {
          width: 100%;
          height: 1px;
          background-color: var(--painelBorderColor);
        }

        .collaborator-row {
          font-weight: 600;
          display: flex;
          justify-content: flex-start;
          padding: 5px 5px 5px 5px;
          align-items: center;
          text-align: center;
          width: 100%;
          min-height: 50px !important;
          max-height: 50px;
        }

        .dash-content-row-selected {
          background-color: var(--systemBackground);
        }
      }
    }
  }
}