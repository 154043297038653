@import "../../../theme//variables.scss";
@import "../../../theme/animations.scss";

.database-items-details-container {
  display: flex;
  flex-direction: column;
  padding: 0px !important;

  .database-list {
    display: flex;
    height: 100%;
    color: var(--gray-700);
    background: var(--blue-gray-50);
    flex-direction: column;

    .list-area {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 20px 30px 0px 30px !important;
      gap: 10px;
      overflow: auto;
      max-height: calc(100vh - 60px);
      margin-bottom: 0px !important;

      .container {
        width: 100%;
        height: 100%;
        background: $systemForeground;
        border-radius: 6px;
        border: solid 1px $painelBorderColor;
        background-color: $systemForeground;
        display: flex;
        flex-direction: column;

        .header {
          display: flex;
          height: 90px;
          gap: 20px;
          padding: 16px 24px;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          .title-wrapper {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            gap: 5px;
            .header-message {
              .page-identifier-wrapper {
                display: flex;
                align-items: center;
                gap: 10px;
                .database-name {
                  color: var(--textPrimary);
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 600;
                }
              }
            }

            .total-items-wrapper {
              display: flex;
              align-items: center;
              width: 100%;
              height: 22px;

              .total-items-count {
                padding: 2px;
                text-align: center;
                font-size: 13px;
                font-weight: 500;
                line-height: 18px;
                color: var(--primary-600, #4146ff);
              }
            }
          }

          .options-wrapper {
            display: flex;
            gap: 20px;
            border-right: 1px solid var(--systemBackground);
            .search-input {
              display: flex;
              align-items: center;
              width: 220px;
              height: 40px;
              background: var(--inputBackgroundColor);
              border-radius: 8px;
              padding: 10px 14px;
            }

            .icon-options {
              display: flex;
              gap: 5px;
              margin-right: 20px;
              .btn-column {
                border: none;
                background: none;
                padding: 10px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                transition: 0.2s;

                &:hover {
                  border: none;
                  padding: 10px;
                  border-radius: 8px;
                  background: var(--systemBackground);
                  box-shadow: none;
                }

                &:focus {
                  box-shadow: none;
                }
              }

              .btn-edit {
                padding: 10px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                transition: 0.2s;
              }
              .btn-edit:hover {
                padding: 10px;
                border-radius: 8px;
                background: var(--systemBackground);
              }
              .btn-edit:focus {
                box-shadow: none;
              }
              .btn-databaseDetails,
              .btn-uploadData,
              .btn-historicUploadData {
                padding: 10px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                transition: 0.2s;
              }
              .btn-databaseDetails:hover,
              .btn-historicUploadData:hover,
              .btn-uploadData:hover {
                padding: 10px;
                border-radius: 8px;
                background: var(--systemBackground);
              }
              .btn-databaseDetails:focus,
              .btn-historicUploadData:focus,
              .btn-uploadData:focus {
                box-shadow: none;
              }
            }
          }

          .buttons {
            display: flex;
            gap: 20px;

            .btn-download-database-list {
              gap: 0px;
            }

            .p-button-link {
              color: var(--primary);
            }
          }
        }

        .datatable-container {
          background: $systemForeground;
          border-radius: 0px 0px 6px 6px;

          .p-datatable {
            .p-datatable-thead > tr > th {
              padding: 16px 24px;
            }
            .tb-header-title {
              font-size: 14px;
              font-weight: 500;
              color: var(--textPrimary);
            }

            .p-datatable-header {
              background: var(--tableHeaderBackground);
            }

            .p-datatable-tbody > tr > td {
              color: var(--textPrimary);
              background-color: var(--systemForeground);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
            }
          }

          .column-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            gap: 4px;
            .column-actions {
              display: flex;
              height: 30px;
              align-items: center;

              .icon-wrapper {
                display: flex;
                justify-content: center;
                padding: 10px;
                transition: 0.2s;
                cursor: pointer;
                &:hover {
                  padding: 10px;
                  border-radius: 8px;
                  background: var(--systemBackground);
                }
              }
            }
          }
        }
      }
    }

    .p-paginator-bottom {
      background: none;
      border-radius: 0;
      border-bottom: none;
    }
  }
}
