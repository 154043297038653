@import "../../../../theme/variables.scss";
@import "../../../../theme/animations.scss";

.print-order-container {
  height: 100%;
  display: flex;
  padding: 30px;

  .backdrop {
    background: $systemForeground;
    width: 100%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    box-shadow:
      0px 2px 4px -2px rgba(16, 24, 40, 0.06),
      0px 4px 8px -2px rgba(16, 24, 40, 0.1);
  }

  &.fullscreen {
    border-radius: 0;
    min-height: 100%;
    height: fit-content;
    width: 100%;
    position: absolute;
    padding: 18px;
    background: rgba(16, 24, 40, 0.5);
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;

    .backdrop {
      min-height: calc(100vh - 36px);
      height: 100%;
      width: 100%;
    }
  }

  .icon {
    height: 34px;
    width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 4px;

    &:hover {
      background: $systemBackground;
      cursor: pointer;
    }
  }

  .content-container {
    height: 100%;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    border-top: $defaultBorder;

    .fields-container {
      flex-basis: 400px;
      display: flex;
      align-items: center;

      .order-status {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        font-weight: 600;

        .processing-status-created,
        .processing-status-finished {
          border: 1px solid var(--green-100);
          background-color: var(--green-50);
          padding: 3px 10px 3px 10px;
          border-radius: 6px;
          color: var(--green500);
          display: flex;
          align-items: center;
          gap: 8px;
        }

        .processing-status-reprocessing-report,
        .processing-status-reprocess-carrier-integration {
          border: 1px solid #b2ddff;
          background-color: #eff8ff;
          padding: 3px 10px 3px 10px;
          border-radius: 6px;
          color: var(--pinkBlue600);
          display: flex;
          align-items: center;
          gap: 8px;
        }

        .processing-status-pending,
        .processing-status-loaded,
        .processing-status-packing,
        .processing-status-report-pending,
        .processing-status-carrier-definition,
        .processing-status-print-pending {
          border: 1px solid var(--gray-300);
          background-color: var(--gray-100);
          padding: 3px 10px 3px 10px;
          border-radius: 6px;
          color: var(--gray500);
          display: flex;
          align-items: center;
          gap: 8px;
        }

        .processing-status-inconsistent-integration,
        .processing-status-carrier-error,
        .processing-status-carrier-integration,
        .processing-status-error-report {
          border: 1px solid #f598a2;
          background-color: #fde9eb;
          padding: 3px 10px 3px 10px;
          border-radius: 6px;
          color: #e91f35;
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }
    }

    .input-section {
      flex-basis: 40%;
      border-right: $defaultBorder;
      padding: 16px 24px;
    }

    .view-section {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      padding: 16px 24px;
      border-left: 1px solid var(--systemBackground);
      background-color: var(--rackExampleBackgroundColor);

      .bigger-input {
        flex-basis: 70%;
      }

      .item-list {
        position: relative;
        height: 100%;
        overflow: auto;

        .p-accordion-content {
          overflow: auto;
        }

        .items {
          position: absolute;
          width: 100%;

          .accordionHeaderTemplate {
            display: grid;
            grid-template-columns: 6fr 3fr 1fr;
            gap: 30px;
            width: 100%;

            .status {
              height: 20px;
              width: 20px;
              background: red;
              border-radius: 50%;

              &:last-child {
                margin-left: auto;
              }
            }
          }
        }
      }
    }
  }

  .footer-container {
    display: flex;
    border-top: var(--defaultBorder);
    padding: 16px 24px;
    gap: 10px;
    justify-content: flex-end;

    .dcp-button:nth-child(1) {
      margin-right: auto;
    }

    .dcp-button:nth-child(2) {
      color: red;
    }
  }
}
