@import "../../../../theme/variables.scss";
@import "../../../../theme/animations.scss";

.database-list {
  display: flex;
  height: 100%;
  color: var(--gray-700);
  background: var(--blue-gray-50);
  flex-direction: column;

  .list-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 30px;
    gap: 10px;
    overflow: auto;
    max-height: calc(100vh - 60px);
    margin-bottom: 10px;

    .container {
      width: 100%;
      height: 100%;
      background: $systemForeground;
      border-radius: 6px;
      border: solid 1px $painelBorderColor;
      background-color: $systemForeground;
      display: flex;
      flex-direction: column;

      .header {
        display: flex;
        height: 90px;
        min-height: 90px;
        gap: 20px;
        padding: 16px 24px;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        overflow-x: auto;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;

        .title-wrapper {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          gap: 5px;
          .header-message {
            .page-identifier-wrapper {
              display: flex;
              align-items: center;
              gap: 10px;
              .database-name {
                color: var(--textPrimary);
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                text-overflow: ellipsis;
                width: 210px;
              }
            }
          }

          .total-items-wrapper {
            display: flex;
            align-items: center;
            width: 100%;
            height: 22px;

            .total-items-count {
              padding: 2px;
              text-align: center;
              font-size: 13px;
              font-weight: 500;
              line-height: 18px;
              color: var(--primary-600, #4146ff);
            }
          }
        }

        .options-wrapper {
          display: flex;
          gap: 20px;
          border-right: 1px solid var(--systemBackground);
          .search-input {
            display: flex;
            align-items: center;
            width: 220px;
            height: 40px;
            background: var(--inputBackgroundColor);
            border-radius: 8px;
            padding: 10px 14px;
          }

          .icon-options {
            display: flex;
            gap: 5px;
            margin-right: 20px;
            .btn-column {
              border: none;
              background: none;
              padding: 10px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              transition: 0.2s;

              &:hover {
                border: none;
                padding: 10px;
                border-radius: 8px;
                background: var(--systemBackground);
                box-shadow: none;
              }

              &:focus {
                box-shadow: none;
              }
            }

            .btn-edit {
              padding: 10px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              transition: 0.2s;
            }
            .btn-edit:hover {
              padding: 10px;
              border-radius: 8px;
              background: var(--systemBackground);
            }
            .btn-edit:focus {
              box-shadow: none;
            }
            .btn-databaseDetails,
            .btn-uploadData,
            .btn-historicUploadData {
              padding: 10px;
              justify-content: center;
              align-items: center;
              gap: 8px;
              transition: 0.2s;
            }
            .btn-databaseDetails:hover,
            .btn-historicUploadData:hover,
            .btn-uploadData:hover {
              padding: 10px;
              border-radius: 8px;
              background: var(--systemBackground);
            }
            .btn-databaseDetails:focus,
            .btn-historicUploadData:focus,
            .btn-uploadData:focus {
              box-shadow: none;
            }
          }
        }

        .buttons {
          display: flex;
          gap: 20px;

          .btn-download-database-list {
            gap: 0px;
          }

          .p-button-link {
            color: var(--primary);
          }
        }
      }

      .datatable-container {
        background: $systemForeground;
        border-radius: 0px 0px 6px 6px;

        .p-datatable {
          .p-datatable-thead > tr > th {
            padding: 16px 24px;
          }
          .tb-header-title {
            font-size: 14px;
            font-weight: 500;
            color: var(--textPrimary);
          }

          .p-datatable-header {
            background: var(--tableHeaderBackground);
          }

          .p-datatable-tbody > tr > td {
            color: var(--textPrimary);
            background-color: var(--systemForeground);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
          }
        }

        .column-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          gap: 4px;
          .column-actions {
            display: flex;
            height: 30px;
            align-items: center;

            .icon-wrapper {
              display: flex;
              justify-content: center;
              padding: 10px;
              transition: 0.2s;
              cursor: pointer;
              &:hover {
                padding: 10px;
                border-radius: 8px;
                background: var(--systemBackground);
              }
            }
          }
        }
      }
    }
  }

  .p-paginator-bottom {
    background: none;
    border-radius: 0;
    border-bottom: none;
  }
}

.database-list-columns {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--gray-100, #f2f4f7);

  .p-overlaypanel-content {
    padding: 0px;
    .popup-wrapper {
      .popup-header {
        display: flex;
        height: 44px;
        width: 240px;
        border-bottom: 1px solid #f2f4f7;
        margin-bottom: 10px;

        .popup-title {
          height: 20px;
          padding: 12px 17px;
          display: flex;
          align-items: flex-start;
          color: var(--gray-700, #344054);
          font-size: 16px;
          font-weight: 600;
        }
      }

      .popup-column-list-wrapper {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 12px;

        .popup-column-list {
          display: flex;
          width: 100%;
          height: 34px;
          padding: 7px 16px;
          align-items: center;
          gap: 12px;

          .p-checkbox .p-checkbox-box {
            width: 16px;
            height: 16px;
            border-radius: 4px;
            font-size: 12px;
          }
          .checkbox-icon {
            display: flex;
            width: 22px;
            height: 22px;
            padding: 2px;
            justify-content: center;
            align-items: center;
            border: 1px solid var(--white, #fff);

            .p-checkbox .p-checkbox-box .p-highlight {
              border-color: #6468ff;
              background: #6468ff;
              color: #fff;
              border-radius: 4px;
            }
          }
          .p-checkbox .p-checkbox-box .p-checkbox-icon {
            font-size: 12px;
          }

          .column-list-label {
            color: #667085;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
          }
        }
      }
    }
  }
}

.download-list-columns-database-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 8px;
  border: none;

  .p-overlaypanel-content {
    padding: 0px;
    .popup-wrapper {
      .popup-header {
        display: flex;
        height: 44px;
        width: 240px;
        border-bottom: 1px solid var(--systemBackground);
        margin-bottom: 10px;

        .popup-title {
          height: 20px;
          padding: 12px 17px;
          display: flex;
          align-items: flex-start;
          color: var(--textPrimary);
          font-size: 16px;
          font-weight: 600;
        }
      }

      .popup-column-download-wrapper {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 12px;

        .popup-excel-download-option,
        .popup-pdf-download-option,
        .popup-csv-download-option {
          cursor: pointer;
          display: flex;
          width: 100%;
          height: 34px;
          padding: 7px 16px;
          align-items: center;
          gap: 12px;
          transition: 0.2s;

          .popup-download-label {
            cursor: pointer;
            color: var(--textPrimary);
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
          }
          &:hover {
            background-color: var(--tableActionButtonHoverBackgroundColor);
          }
        }
      }
    }
  }
}

// Overlays
.download-options-form {
  padding: 0;
}
