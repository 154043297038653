@import "../../../../theme/variables.scss";

.page-body-settings-create-client {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .create-client-settings {
    background-color: var(--systemBackground);
    padding: 34px;
    height: 100%;

    .form-row {
      // margin-top: 20px;

      &:first-child {
        margin-top: 0;
      }
    }

    .container {
      display: flex;
      height: 100%;
      flex-direction: column;

      .header {
        height: 60px;
        padding: 18px 30px;
        border-bottom: 1px solid var(--systemBackground);
        border-radius: $inputBorderRadius $inputBorderRadius 0 0;
        background: $systemForeground;

        h3 {
          margin: 0;
          color: var(--textPrimary);
        }
      }

      .settings-content {
        height: 100%;
        display: flex;

        .settings-container {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;

          .container {
            background: $systemForeground;
            display: flex;
            align-items: center;
            overflow: auto;
            padding: 30px 30px;

            .group-container {
              width: 100%;
              max-width: 720px;

              h3.page-header {
                font-size: 16px;
                font-weight: 600;
                color: var(--textPrimary);
                margin: 0;
              }

              .page-header {
                border-bottom: 1px solid var(--systemBackground);
                padding-bottom: 10px;
              }

              .custom-input-container {
                width: 100%;
                flex: auto;
              }

              .p-colorpicker-preview {
                height: 40px;
                width: 40px;
              }

              .subgroup-container-create-client {
                margin-top: 20px;
                color: var(--textPrimary);
                
                p {
                  font-size: 14px;
                  font-weight: 400;
                  color: var(--textPrimary);
                  margin: 0;
                  padding-bottom: 20px;
                }

                .wms-password {
                  .p-inputtext,
                  .p-password-input {
                    width: 100%;
                  }
                  .p-icon {
                    height: 15px;
                    width: 15px;
                    margin-left: 95%;
                  }
                }
              }
            }
          }
        }
      }

      .footer {
        height: 60px;
        flex-shrink: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 30px;
        border-top: 1px solid var(--systemBackground);
        border-radius: 0 0 $inputBorderRadius 0;
        background: $systemForeground;
      }
    }
  }
}
