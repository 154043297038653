// Search modal section title
.nav-top-menu-search {
  .search-input {
    display: flex;
    width: 100%;
    align-items: center;
    border-radius: 10px;
    background-color: var(--inputBackgroundColor);
    padding: 1rem 1rem;

    .p-inputtext {
      padding: 0;
      font-size: var(--defaultFontSize);
      flex-grow: 1;
    }

    .p-inputtext::placeholder {
      font-size: var(--defaultFontSize);
    }
  }

  .p-submenu-header {
    padding: 0.75rem 0rem 0.75rem 0rem;
  }

  .result-section-wrapper {
    display: flex;
    padding: 2px 20px;
    align-items: center;
    gap: 30px;

    .result-section-title {
      color: var(--textSecondary) !important;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  .result-icon-wrapper {
    display: flex;
    margin-left: 20px;
    justify-content: center;
    align-items: center;

    .resultIcon-Produtos {
      border-radius: 5px;
      background: var(--systemBackground);
      padding: 4px 3.999px 4px 4px;
    }

    .resultIcon-Dashboards {
      border-radius: 5px;
      background: var(--systemBackground);
      padding: 4px 3.999px 4px 4px;
    }

    .resultIcon-Databases {
      border-radius: 5px;
      background: var(--systemBackground);
      padding: 4px 3.999px 4px 4px;
    }

    .resultIcon-Menu {
      border-radius: 5px;
      padding: 4px 3.999px 4px 4px;
    }
  }

  .result-title-wrapper {
    display: flex;
    padding: 4px;
    align-items: center;
    gap: 30px;

    .result-body-title {
      color: var(--textPrimary);
      font-size: 14px;
      font-style: normal;
      line-height: 18px;
    }
  }

  .content {
    min-height: 100px;
    min-width: 100%;
    margin: -20px;
    margin-top: 10px;
    margin-bottom: 10px;

    .empty-search {
      margin-top: 10px;
      display: flex;
      align-content: center;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      height: 80px;
    }

    .p-menu {
      border: 0px;
      width: 100%;
    }
  }

  .footer {
    border-top: solid 1px var(--systemBackground);
    padding: 10px;
    margin: -20px;
    margin-top: 30px;

    .items {
      display: flex;
      flex-direction: row;
      gap: 10px;

      .item {
        font-weight: 500;

        .tag {
          font-weight: 600;
          padding: 4px;
          background-color: var(--systemBackground);
          color: var(--textTertiary);
          border-radius: 6px;
        }
      }
    }
  }
}
