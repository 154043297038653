@import "../../../../theme/variables.scss";

.dialog-area-history-details {
  display: flex;
  height: 100% !important;
  max-height: 98% !important;
  width: 99% !important;
  padding: 0px;
  .p-dialog-content {
    border-radius: 8px !important;
    padding: 0px !important;
  }
  .p-dialog-header {
    display: none;
  }
  .barcode-read {
    display: flex;
    width: 100%;
    height: 100%;
    font-weight: 400;

    .barcode-read-content {
      background-color: var(--systemForeground);
      display: flex;
      height: -moz-fit-content;
      /* height: 100%; */
      flex-grow: 1;
      border-radius: 6px;

      .barcode-details {
        display: flex;
        flex-grow: 1;
        width: 100%;
        max-width: 470px;
        height: 100%;
        flex-direction: column;
        padding: 30px 30px;
        gap: 15px;
        background-color: var(--systemForeground);
        border-radius: 6px 0px 0px 6px;
        overflow: auto;

        .return-option-wrapper {
          display: flex;
          gap: 10px;
          margin-bottom: 20px;

          .icon-return {
            display: flex;
            cursor: pointer;
          }
          .return-text-title {
            font-weight: 700;
            font-size: 16px;
            color: $textPrimary;
          }
        }

        .barcode-form-wrapper {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .identifier-location-label {
            font-weight: 500;
            font-size: 16px;
          }

          .content-location {
            font-weight: 600;
            font-size: 16px;

            .reliability-reliable {
              padding: 4px;
              background-color: #8cd8c0;
              border-radius: 4px;
              color: var(--textPrimary);
            }

            .reliability-lessReliable {
              background-color: #ffe78a;
              border-radius: 4px;
              color: var(--textPrimary);
            }

            .reliability-unreliable {
              padding: 4px;
              background-color: #fba6ba;
              border-radius: 4px;
              color: var(--textPrimary);
            }
          }

          .inventory-link {
            width: fit-content;
            text-decoration: underline;
            font-weight: 500;
            font-size: 16px;
            color: #4146ff;
            cursor: pointer;
          }
        }

        .issue-details-wrapper {
          gap: 6px;
          display: flex;
          flex-direction: column;
          .issue-details-text {
            display: flex;
            background-color: #f5f8ff;
            border-radius: 8px;
            align-items: center;
            padding: 10px 14px 10px 14px;
          }
        }
        .input-container {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          gap: 6px;

          &.lpn-status {
            flex-direction: row;
            gap: 10px;
            align-items: center;
          }
        }

        .input {
          flex-grow: 1;

          .p-inputtext,
          .p-dropdown,
          .p-inputnumber {
            color: #000;
            width: 100%;
            font-weight: 600;
            &.lpn-status {
              border: 1px solid #f5f8ff;
              background-color: var(--dropdown-selected-background-color);
              .p-dropdown-label {
                color: var(--dropdown-selected-text-color);
              }
            }
          }
        }

        .sku-wrapper {
          display: flex;
          flex-grow: 1;
          justify-content: space-between;
          flex-direction: row;
          margin-top: 10px;
          .icon-add {
            display: flex;
            cursor: pointer;
          }
        }

        .dcp-form {
          padding: 0;
          gap: 30px !important;
          .form-row {
            flex-wrap: wrap;
          }
        }

        // itens-table component
        .itens-table {
          width: 100%;
          height: 100%;
        }

        .content-body {
          display: flex;
          flex-direction: column;
          gap: 10px;
          margin-top: 5px;
          .dcp-table {
            width: 100%;

            .itens-table {
              .p-datatable .p-datatable-tbody > tr > td {
                padding: 22px 24px !important;
              }
            }
          }
        }
        .other-locations-wrapper {
          .location-street {
            display: flex;
            align-items: center;
            gap: 15px;
            padding: 8px 0px;
            border-left: 2px;
            .locations-tag {
              width: 3px;
              height: 15px;
              border-radius: 6px;
              background-color: #fd7584;
            }
            .locations-name {
              font-weight: 600;
              font-size: 14px;
              color: $inputFontColor;
            }
          }
        }
      }

      .barcode-image {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        align-items: center;
        background: var(--systemBackground);
        overflow: auto;

        .location-select-header {
          display: flex;
          align-items: center;
          flex-direction: row;
          width: 100%;
          padding: 15px;

          .location-image-header-buttons {
            display: flex;
            justify-content: flex-start;
            width: 40%;
            .p-button {
              margin: 0px;
              padding: 5px;
            }
          }

          .location-select-dropdown {
            display: flex;
            align-items: center;
            gap: 20px;
            width: 60%;

            .right-icon-arrow,
            .left-icon-arrow {
              cursor: pointer;
              border: 1px solid var(--systemBackground);
              background-color: var(--systemBackground);
              border-radius: 50%;
              transition: 0.2s;
              align-items: center;
            }
            .right-icon-arrow-disabled,
            .left-icon-arrow-disabled {
              cursor: not-allowed;
              background-color: brighten(var(--systemBackground), 10%);
              align-items: center;
            }

            .right-icon-arrow:hover {
              background-color: var(--iconActiveColor);
              border: 1px solid var(--iconActiveColor);
              border-radius: 50%;
              align-items: center;
            }

            .left-icon-arrow:hover {
              background-color: var(--iconActiveColor);
              border: 1px solid var(--iconActiveColor);
              border-radius: 50%;
              align-items: center;
            }

            .location-name-dropdown {
              width: 200px;
              background-color: var(
                --inputBackgroundColorWhiteOnLightTheme
              ) !important;

              .p-dropdown-label {
                background-color: var(
                  --inputBackgroundColorWhiteOnLightTheme
                ) !important;
              }

              .p-dropdown-trigger {
                background-color: var(
                  --inputBackgroundColorWhiteOnLightTheme
                ) !important;
              }
            }
          }
        }

        .location-image {
          display: flex;
          align-items: center;
          height: 100% !important;
          max-height: 100%;
          max-height: 90%;
          position: relative;
          padding: 23px 35px 23px 35px !important;
          object-fit: contain;

          .location-img {
            height: 100% !important;
            width: 100% !important;
            cursor: "grab";
          }

          .p-image-preview-container > img {
            height: 100%;
          }
        }

        .no-image {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 100%;
          font-size: 24px;
          font-weight: 600;
          color: var(--textTertiary);
        }

        .image-gallery {
          display: flex;
        }

        .main-image-container {
          flex: 1;
          text-align: center;
        }

        .paginator {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          .paginator-content {
            font-weight: 600;
          }
        }
      }

      .barcode-thumbnail-session {
        align-items: center;
        padding-top: 20px;
        display: flex;
        flex-grow: 1;
        height: 100%;
        width: 100%;
        max-width: 130px;
        flex-direction: column;
        padding: 30px 18px;
        background-color: var(--systemForeground);
        flex-wrap: wrap;

        .barcode-image-thumbnail-title {
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          margin-bottom: 30px;
        }

        .thumbnail-gallery {
          display: flex;
          flex-direction: column;
        }

        .thumbnail {
          width: 94px;
          height: 69px;
          margin-bottom: 5px;
          cursor: pointer;
          opacity: 0.5;
        }

        .thumbnail.selected {
          opacity: 1.5;
          border: 2px solid #4146ff;
        }

        .p-image-preview-container img {
          max-width: none !important;
          max-height: none !important;
          transform: scale(3);
        }
      }
    }

    hr {
      width: 100%;
      border: 0;
      height: 1px;
      margin: 30px 0px;
      background: #d5d9eb;
    }
  }
}
