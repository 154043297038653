@import "../../../../theme/variables.scss";

.main-dashboard-initial-setup-container {
  .configure-dashboard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    h3 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
      color: $textPrimary;
    }
  }
}

.content-dashboard-main-container {
  background-color: var(--systemForeground);
  color: var(--sideMenuLabelColor);
  height: 200px;
  width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  h3 {
    color: var(--textPrimary);
  }
  .buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
