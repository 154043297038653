.dcp-popup-delete-confirmation {
  display: flex;
  width: 334px;
  height: 155px;
  z-index: 1000;

  &.p-overlaypanel {
    transform-origin: center center;
    &:before,
    &:after {
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .p-overlaypanel-content {
    padding: 18px;
    .popup-wrapper {
      display: flex;
      flex-direction: column;
      .popup-header {
        display: flex;
        height: 100%;
        display: flex;
        justify-content: space-between;
        gap: 18px;
        .popup-icon-wrapper {
          padding: 0px 0px 5px;
          .popup-icon-delete {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 3px;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: #ffe8ea;
            border: 8px solid #ffeff0;
          }
        }
        .popup-body-wrapper {
          .popup-title-wrapper {
            display: flex;
            height: 24px;
            flex-direction: column;
            justify-content: center;
            .popup-title-message {
              color: var(--textPrimary);
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px;
            }
          }

          .popup-description-wrapper {
            .popup-body-message {
              color: var(--textTertiary);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;
            }
          }
        }
      }
      .popup-footer-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 12px;
      }
    }
  }
}
