@import "../../../../theme/variables.scss";

.database-new-entry-edit {
  .p-dialog-footer {
    padding: 30px 40px;
    border-top: 1px solid var(--systemBackground);
    .finish-btn {
      margin: 0;
    }
  }

  .p-dialog-header {
    padding: 30px 40px;
    border-bottom: var(--default-border);

    .database-name {
      color: var(--textPrimary);
      font-size: 20px;
      font-weight: 600;
    }
  }

  .radio-options-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .radio-option-preview {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  .fields-fixed-container {
    padding: 0 40px;
    overflow-y: auto;

    .input-wrapper {
      margin: 20px 0;

      .label {
        margin-bottom: 8px;
        display: flex;
        flex-direction: column;
        gap: 4px;

        .name {
          color: var(--textPrimary);
          font-size: 14px;
          font-weight: 500;
          display: flex;
          align-items: center;

          .help-icon {
            margin-left: 4px;
          }
        }
      }

      .image-upload-container {
        display: flex;
        gap: 10px;
        align-items: center;

        .image-preview {
          cursor: pointer;
        }
      }
    }
  }
}

.image-preview-dialog {
  width: 90vw;
  max-width: 100%;
  max-height: 100%;
  .p-dialog-content {
    padding: 0;
    overflow: hidden;
  }

  .image-preview-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 80vh;
    overflow: auto;
    border-top: 1px solid var(--systemBackground);
    background-color: var(--systemForeground);
    padding: 20px;

    img {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }
}

.image-upload-container {
  display: flex;
  align-items: center;
  gap: 10px;

  .image-upload-preview-container {
    display: flex;
    gap: 10px;
    height: 20px;

    .image-preview-delete,
    .image-preview {
      cursor: pointer;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.8;
      }
    }

    .button-delete-image {
      display: flex;
      width: 22px !important;
      height: 22px !important;
      align-items: center;
      justify-content: center;
      padding: 0px !important;
      gap: 0px !important;

      &:focus,
      &:hover {
        background-color: transparent !important;
        border: none !important;
        border-color: transparent !important;
        box-shadow: none !important;
      }
    }
  }
}
.p-confirm-popup:before,
.p-confirm-popup:after {
  background-color: var(--systemBackground);
  border: var(--systemBackground);
  border-bottom-color: var(--systemBackground);
}

.dcp-popup-delete-confirmation {
  display: flex;
  flex-direction: column;
  height: 160px !important;
  background-color: var(--systemForeground);
  .p-confirm-popup-content {
    border-radius: 12px;
    background-color: var(--systemForeground);
    padding: 18px 0px 0px 18px !important;
    .p-confirm-popup-message {
      margin: 0px !important;
      .popup-wrapper-confirm-delete-image {
        display: flex;
        flex-direction: column;
        .popup-header {
          display: flex;
          height: 100%;
          display: flex;
          justify-content: space-between;
          gap: 18px;
          .popup-icon-wrapper {
            padding: 0px 0px 5px;
            .popup-icon-delete {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 3px;
              width: 50px;
              height: 50px;
              border-radius: 50%;
              background-color: #ffe8ea;
              border: 8px solid #ffeff0;
            }
          }
          .popup-body-wrapper {
            .popup-title-wrapper {
              display: flex;
              height: 24px;
              flex-direction: column;
              justify-content: center;
              .popup-title-message {
                color: var(--textPrimary);
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
              }
            }

            .popup-description-wrapper {
              .popup-body-message {
                color: var(--textTertiary);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
              }
            }
          }
        }
      }
    }
  }
  .p-confirm-popup-footer {
    border-radius: 12px;
    background-color: var(--systemForeground);
    .popup-footer-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 12px;
    }
  }
}
