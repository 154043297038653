body {
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
}

$fontFamily: "Quicksand", sans-serif;
$mobileBreakPoint: 768px;

$systemBackground: var(--systemBackground);
$systemForeground: var(--systemForeground);
$systemContentBorderColor: var(--systemContentBorderColor);
$loginBackground: var(--loginBackgroundColor);

$primary: var(--primary);
$primaryHover: var(--primaryHover);

$textPrimary: var(--textPrimary);
$textSecondary: var(--textSecondary);
$textTertiary: var(--textTertiary);

// Navbar
$iconNavBarDefaultColor: var(--iconNavBarDefaultColor);
$iconNavBarActiveColor: var(--iconNavBarActiveColor);

//Painel
$painelBorderColor: var(--painelBorderColor);
$panelBorderRadius: 8px;
$painelBgColorHover: var(--painelBgColorHover);
$painelLabelColor: var(--painelLabelColor);

//Button
$buttonSizeSM: 36px;
$buttonSizeMD: 40px;
$buttonSizeLG: 44px;
$buttonSizeXL: 48px;
$buttonSizeXXL: 60px;
$buttonFontSize: 14px;
$buttonFontWeight: 500;
$buttonBorderRadius: 8px;
$primaryButtonFontColor: var(--primaryButtonFontColor);
$secondaryButtonFontColor: var(--secondaryButtonFontColor);
$primaryButtonFontColorHover: var(--primaryButtonFontColorHover);
$primaryButtonBackgroundColor: var(--primaryButtonBackgroundColor);

//Input
$inputSize: 40px;
$inputBorderRadius: 8px;
$inputPadding: 12px 14px;
$inputBackgroundColor: var(--inputBackgroundColor);
$inputBackgroundColorFocus: var(--inputBackgroundColorFocus);
$inputBackgroundColorHover: var(--inputBackgroundColorHover);
$inputBorderColor: var(--inputBorderColor);
$inputBorderColorFocus: var(--inputBorderColorFocus);
$inputBorderColorHover: var(--inputBorderColorHover);
$inputFontColor: var(--inputFontColor);
$inputFontColorHover: var(--inputFontColorHover);
$inputFontColorFocus: var(--inputFontColorFocus);
$inputPlaceholderColor: var(--inputPlaceholderColor);

//Font & Size
$defaultFontSize: var(--defaultFontSize);
$defaultFontWeight: var(--defaultFontWeight);

//Icons
$iconDefaultSize: var(--iconDefaultSize);
$iconDefaultColor: var(--iconDefaultColor);
$iconActiveColor: var(--iconActiveColor);
$iconHotKeyBg: var(--iconHotKeyBg);
$iconHotKeyLabelColor: var(--iconHotKeyLabelColor);
$iconHotKeyBorderColor: var(--iconHotKeyBorderColor);

//Shadows
$defaultBoxShadow: var(--defaultBoxShadow);
$sideMenuSizeIconShadow: var(--sideMenuSizeIconShadow);

//Border
$defaultBorder: var(--defaultBorder);

// Dashboards
$dashboardStrokesColor: var(--dashboardStrokesColor);
$dashboardDataColor: var(--dashboardDataColor);
$dashboardInventoryCardBackground: var(--dashboardInventoryCardBackground);
$dashboardWmsCardBackground: var(--dashboardWmsCardBackground);
$dashboardMesCardBackground: var(--dashboardMesCardBackground);
$dashboardSequenceCardBackground: var(--dashboardSequenceCardBackground);
$dashboardWarehouseCardBackground: var(--dashboardWarehouseCardBackground);
$dashboardAMCCardBackground: var(--dashboardAMCCardBackground);

// Dashboard Card
$dashboardWarehouseCardBackground: var(--dashboardWarehouseCardBackground);

//Table
$tableHeaderBackground: var(--tableHeaderBackground);
$tableHeaderBackgroundHover: var(--tableHeaderBackgroundHover);
$tableHeaderTitle: var(--tableHeaderTitle);
$tableBodyBackground: var(--tableBodyBackground);

$tableStatusIconActiveColor: var(--tableStatusIconActiveColor);
$tableStatusLabelActive: var(--tableStatusLabelActive);
$tableStatusLabelBackgroundActive: var(--tableStatusLabelBackgroundActive);
$tableStatusIconInactiveColor: var(--tableStatusIconInactiveColor);
$tableStatusLabelInactive: var(--tableStatusLabelInactive);
$tableStatusLabelBackgroundInactive: var(--tableStatusLabelBackgroundInactive);

$tableActionButtonIconColor: var(--tableActionButtonIconColor);
$tableActionButtonBackgroundColor: var(--tableActionButtonBackgroundColor);
$tableActionButtonHoverIconColor: var(--tableActionButtonHoverIconColor);
$tableAvatarLabelInactive: var(--tableAvatarLabelInactive);
$tableAvatarLabelBackgroundInactive: var(--tableAvatarLabelBackgroundInactive);
$tableActionButtonHoverBackgroundColor: var(
  --tableActionButtonHoverBackgroundColor
);
$tableAvatarLabelBackground: var(--tableAvatarLabelBackground);
$tableAvatarLabelColor: var(--tableAvatarLabelColor);
$tableRowHoverBackgroundColor: var(--tableRowHoverBackgroundColor);

$tableProductWmsBgColor: var(--tableProductWmsBgColor);
$tableProductPlatformBgColor: var(--tableProductPlatformBgColor);
$tableProductSequenceBgColor: var(--tableProductSequenceBgColor);
$tableProductInventoryBgColor: var(--tableProductInventoryBgColor);
$tableProductMesBgColor: var(--tableProductMesBgColor);
$tableProductWarehouseBgColor: var(--tableProductWarehouseBgColor);
$tableProductAreaMovementControlBgColor: var(
  --tableProductAreaMovementControlBgColor
);

$tableProductWmsLabelColor: var(--tableProductWmsLabelColor);
$tableProductPlatformLabelColor: var(--tableProductPlatformLabelColor);
$tableProductSequenceLabelColor: var(--tableProductSequenceLabelColor);
$tableProductInventoryLabelColor: var(--tableProductInventoryLabelColor);
$tableProductMesLabelColor: var(--tableProductMesLabelColor);
$tableProductWarehouseLabelColor: var(--tableProductWarehouseLabelColor);
$tableProductAreaMovementControlLabelColor: var(
  --tableProductAreaMovementControlLabelColor
);
$tableIconColor: var(--tableIconColor);
$tableIconHoverColor: var(--tableIconHoverColor);
$tableProductCountBg: var(--tableProductCountBg);
$tableProductCountLabel: var(--tableProductCountLabel);

$shadowBlue: var(--shadowBlue);
$primary50: var(--primary50);
$gray100: var(--gray100);
$gray200: var(--gray200);
$gray400: var(--gray400);
$gray500: var(--gray500);
$gray900: var(--gray900);

//Misc Colors
$indigo: #6468ff;
$indigo10: #6468ff1a;

$blueGray50: var(--blueGray50);
$blueGray100: var(--blueGray100);
$blueGray200: var(--blueGray200);
$blueGray300: var(--blueGray300);
$blueGray400: var(--blueGray400);
$blueGray500: var(--blueGray500);
$blueGray600: var(--blueGray600);
$blueGray700: var(--blueGray700);
$blueGray800: var(--blueGray800);
$blueGray900: var(--blueGray900);

$indigo50: var(--indigo50);
$indigo100: var(--indigo100);
$indigo200: var(--indigo200);
$indigo300: var(--indigo300);
$indigo400: var(--indigo400);
$indigo500: var(--indigo500);
$indigo600: var(--indigo600);
$indigo700: var(--indigo700);
$indigo800: var(--indigo800);
$indigo900: var(--indigo900);

$primary50: var(--primary50);
$primary100: var(--primary100);
$primary200: var(--primary200);
$primary300: var(--primary300);
$primary400: var(--primary400);
$primary500: var(--primary500);
$primary600: var(--primary600);
$primary700: var(--primary700);
$primary800: var(--primary800);
$primary900: var(--primary900);
