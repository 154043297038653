.processing-status-created,
.processing-status-finished {
  border: 1px solid var(--green-100);
  background-color: var(--green-50);
  padding: 3px 10px 3px 10px;
  border-radius: 6px;
  color: var(--green500);
  display: flex;
  align-items: center;
  gap: 8px;
}

.processing-status-reprocessing-report,
.processing-status-reprocess-carrier-integration {
  border: 1px solid #b2ddff;
  background-color: #eff8ff;
  padding: 3px 10px 3px 10px;
  border-radius: 6px;
  color: var(--pinkBlue600);
  display: flex;
  align-items: center;
  gap: 8px;
}

.processing-status-pending,
.processing-status-loaded,
.processing-status-packing,
.processing-status-report-pending,
.processing-status-carrier-definition,
.processing-status-print-pending {
  border: 1px solid var(--gray-300);
  background-color: var(--gray-100);
  padding: 3px 10px 3px 10px;
  border-radius: 6px;
  color: var(--gray500);
  display: flex;
  align-items: center;
  gap: 8px;
}

.processing-status-inconsistent-integration,
.processing-status-carrier-error,
.processing-status-carrier-integration,
.processing-status-error-report {
  border: 1px solid #f598a2;
  background-color: #fde9eb;
  padding: 3px 10px 3px 10px;
  border-radius: 6px;
  color: #e91f35;
  display: flex;
  align-items: center;
  gap: 8px;
}
